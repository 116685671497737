import React from "react";

/*
404 page, maybe harness some more iStock here?
*/

class NotFound extends React.Component {
  render() {
    return <h1>404 :(</h1>;
  }
}

export default NotFound;
