import React from "react";
import Helmet from "react-helmet";

const Meta = ({
  title = "Pixel Mob - Royalty-Free Stock Photos",
  description = "Find royalty-free stock photos from multiple sources for use on your website, blog, social media, and more."
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="Description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
};

export default Meta;
