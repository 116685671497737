import React from "react";
import history from "./history";

class SearchBar extends React.Component {
  state = {
    query: ""
  };

  getSearchPath = () => `${window.location.origin}/search/${this.state.query}`;

  setSearchPath = () => {
    history.push(`/search/${this.state.query.toLowerCase()}`);
  };

  queryChangeHandler = event => {
    this.setState({
      query: event.currentTarget.value || ""
    });
  };

  queryKeydownHandler = event => {
    if (event.key === "Enter") this.setSearchPath();
  };

  componentDidMount = () => {
    if (this.props.query) {
      this.setState({
        query: this.props.query
      });
    }
  };

  render() {
    return (
      <div className="search__bar">
        <input
          type="text"
          name="search"
          placeholder="Start your search"
          value={this.state.query}
          onChange={event => this.queryChangeHandler(event)}
          onKeyDown={event => this.queryKeydownHandler(event)}
          autoFocus={this.props.focus}
        />
      </div>
    );
  }
}

export default SearchBar;
