import React, { useState } from "react";
import LoginModal from "../components/LoginModal";
import firebase from "firebase/app";
import { withRouter } from "react-router-dom";

const StartLoginFlow = (action) => {
  const authProvider = new firebase.auth[`${action}AuthProvider`]();

  firebase.auth().signInWithRedirect(authProvider);
};

const Login = ({ history }) => {
  const [loading, setLoading] = useState(true);

  firebase.auth().onAuthStateChanged((user) => {
    setLoading(false);
    if (user) return history.push("/");
  });

  return loading ? (
    <div className="content has-loading-icon">
      <svg
        width={100}
        height={100}
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="loading-icon"
      >
        <circle cx="49.5" cy={5} r={5} fill="#f9d374" />
        <circle cx="17.5" cy="18.75" r={5} fill="#3a8589" />
        <circle cx={5} cy="49.5" r={5} fill="#5cbfc3" />
      </svg>
    </div>
  ) : (
    <LoginModal authenticate={StartLoginFlow} />
  );
};

export default withRouter(Login);
