import React from "react";
import Subheader from "../components/Subheader";
import Image from "../components/Image";
import Modal from "../components/Modal";
import AnnouncementBar from "../components/AnnouncementBar";
import { query_phrase } from "../helpers/colors";
import Meta from "../components/meta";

class Search extends React.Component {
  state = {
    photos: [],
    results: 0,
    open_photo: null,
    loading: true,
    page: 1,
    sponsored_photos: null,
  };

  /*
  state
  results are the amount of results to show as an integer
  open_photo is for the modal
  */

  providers = {
    pexels: {
      only_one_page: false,
    },
    barnimages: {
      only_one_page: true,
    },
    istock: {
      only_one_page: false,
    },
    freerangestock: {
      only_one_page: false,
    },
  };

  /*
  providers
  the first provider is taken to grab the photos first. this can always be moved around to adjust which photos are pulled first
  only_one_page is for sites like barnimages where it's an XML feed and pages aren't a thing, so it will just grab one set
  */

  last_search = "";

  requestPhotos = async (predeterminedQuery) => {
    const query = predeterminedQuery || this.props.match.params.query;
    const page = this.state.page;
    this.last_search = query;

    const provider_keys = Object.keys(this.providers);
    const first_provider = provider_keys[0];
    provider_keys.shift();
    const rest_of_providers = [...provider_keys].join(",");

    const fetch_provider = (provider, increment_query, callback) => {
      return fetch(
        `/get?provider=${provider}&query=${query}&page=${page}&increment=${increment_query}`
      )
        .then((response) => response.json())
        .then((data) => {
          let current_photos = data.photos.photos || data.photos;
          if (!current_photos) return false;

          const photos = this.state.photos;
          const results = this.state.results;

          if (callback) callback();

          /*
            callback is for fetching the second set of photos, used below
            */

          this.setState({
            photos: photos.concat(...current_photos),
            results: results + data.results,
            loading: false,
          });
        })
        .catch(console.log);
    };

    fetch_provider(first_provider, true, () => {
      fetch_provider(rest_of_providers, false);
    });

    /*
    fetch_provider
    fetch first provider, then the rest
    the second parameter is used to increment the query in the database so you can see analytics on what searches perform
    we don't want to increment a query by 2 every time because we talk to the server twice, so we only increment when the second set of providers are called
    */
  };

  updateSponsoredPhoto = (query) => {
    this.setState({ sponsored_photos: null });

    fetch(`/get_sponsored?query=${query}`)
      .then((response) => response.json())
      .then((photo) => {
        this.setState({ sponsored_photos: photo });
      });
  };

  openPhoto = (photo, provider) => {
    let open_photo = {
      photo,
      provider,
    };

    if (!photo || !provider) open_photo = null;
    else this.updateSponsoredPhoto(this.last_search);

    this.setState({
      open_photo,
    });
  };

  /*
  openPhoto
  used to open a photo in the modal
  */

  throttle(fn, wait) {
    var time = Date.now();
    return function () {
      if (time + wait - Date.now() < 0) {
        fn();
        time = Date.now();
      }
    };
  }

  /*
  throttle
  this is used for infinite scrolling so we don't check every few milliseconds when scrolling, only every 2 seconds
  */

  componentDidMount = () => {
    this.requestPhotos();

    window.addEventListener(
      "scroll",
      this.throttle(() => {
        const scrollPosition = window.pageYOffset;
        const windowSize = window.innerHeight;
        const bodyHeight = document.body.offsetHeight;
        const distance = Math.max(
          bodyHeight - (scrollPosition + windowSize),
          0
        );

        if (distance < 2000 && this.state.photos.length > 25) {
          const page = this.state.page + 1;
          this.setState({ page }, () => this.requestPhotos());
        }
      }, 2000)
    );
  };

  componentWillReceiveProps = (props) => {
    if (props.match.params.query !== this.last_search) {
      this.setState(
        {
          photos: [],
          results: 0,
          open_photo: null,
        },
        () => this.requestPhotos(props.match.params.query)
      );
    }
  };

  render() {
    const query = this.props.match.params.query;

    return (
      <>
        <Meta
          title={`Pixel Mob - Royalty-Free Stock ${query_phrase(query, true)}`}
          description={`Find royalty-free stock ${query_phrase(
            query,
            true,
            true
          )} from multiple sources for use on your website, blog, social media, and more.`}
        />

        <Modal
          meta={this.state.open_photo}
          openPhoto={this.openPhoto}
          user={this.props.user}
          user_lists={this.props.user_lists}
          toggleSave={this.props.toggleSave}
          query={query}
          sponsored_photos={this.state.sponsored_photos}
        />

        <div className="content-wrapper">
          <Subheader
            refresh={this.state.results}
            results={this.state.results}
            query={query}
          />

          {this.state.loading ? (
            <div className="content has-loading-icon">
              <svg
                width={100}
                height={100}
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="loading-icon"
              >
                <circle cx="49.5" cy={5} r={5} fill="#f9d374" />
                <circle cx="17.5" cy="18.75" r={5} fill="#3a8589" />
                <circle cx={5} cy="49.5" r={5} fill="#5cbfc3" />
              </svg>
            </div>
          ) : (
            <div className="content" id="gallery">
              {this.state.photos.map((photo, index) =>
                photo ? (
                  <Image
                    key={`photo-${photo.photo.url}-${index}`}
                    photo={photo}
                    openPhoto={this.openPhoto}
                  />
                ) : null
              )}
            </div>
          )}
        </div>

        <AnnouncementBar />
      </>
    );
  }
}

export default Search;
