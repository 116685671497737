import React from "react";
import Modal from "../components/Modal";
import Image from "./../components/Image";
import ListBar from "./../components/ListBar";
import firebase from "firebase/app";
import { keys, db_email } from "./../components/helpers";
import base from "../components/base";

class Library extends React.Component {
  state = {
    open_photo: null,
    active_list_key: null,
    user: null
  };

  /*
  this is for the users library
  open_photo is for the photo modal
  */

  newList = name => {
    const user_lists = this.state.user_lists;

    user_lists[name] = false;

    this.setState({
      user_lists
    });
  };

  deleteList = key => {
    const user_lists = this.state.user_lists;
    user_lists[key] = null;

    this.setState({
      user_lists
    });
  };

  openPhoto = (photo, provider) => {
    let open_photo = {
      photo,
      provider
    };

    if (!photo || !provider) open_photo = null;

    this.setState({
      open_photo
    });
  };

  renderLists = () => {
    const lists = this.state.user_lists;
    if (!lists) return null;

    return keys(lists).map(listKey => (
      <li
        key={listKey}
        className={listKey === this.state.active_list_key ? "active" : ""}
        onClick={() => {
          this.setState({ active_list_key: listKey });
        }}
      >
        {`${listKey} (${keys(lists[listKey]).length || 0})`}
      </li>
    ));
  };

  renderPhotos = () => {
    if (!this.state.user_lists) return null;
    const list = this.state.user_lists[this.state.active_list_key];
    if (!list)
      return (
        <p className="extend">
          Doesn't look like you have any photos in here yet! Why not search for
          some{" "}
          <a href="/search/mountains" className="underline">
            mountains
          </a>
          ?
        </p>
      );

    return keys(list).map(photoKey => (
      <Image
        key={list[photoKey].photo.url}
        photo={list[photoKey]}
        openPhoto={this.openPhoto}
      />
    ));
  };

  prep_lists = user_email => {
    base.syncState(`user_lists/${db_email.to(user_email)}`, {
      context: this,
      state: "user_lists",
      defaultValue: { Favorites: false },
      then() {
        this.setState({
          active_list_key: "Favorites",
          user: user_email
        });
      }
    });

    /*
    sync the users list with the firebase DB
    */
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) this.prep_lists(user.email);
    });
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          meta={this.state.open_photo}
          openPhoto={this.openPhoto}
          user={this.props.user}
          user_lists={this.props.user_lists}
          toggleSave={this.props.toggleSave}
        />

        <div className="content-wrapper">
          <ListBar
            newList={this.newList}
            active_list={this.state.active_list_key}
            deleteList={this.deleteList}
          />

          <div className="content no-extras">
            <ul className="lists">{this.renderLists()}</ul>

            <div id="gallery">{this.renderPhotos()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Library;
