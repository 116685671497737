import pluralize from "pluralize";

const colors = [
  "aqua",
  "aquamarine",
  "azure",
  "beige",
  "bisque",
  "black",
  "blue",
  "brown",
  "chartreuse",
  "chocolate",
  "coral",
  "cornsilk",
  "crimson",
  "cyan",
  "darkorange",
  "fuchsia",
  "gainsboro",
  "gold",
  "gray",
  "grey",
  "green",
  "indigo",
  "ivory",
  "khaki",
  "lavender",
  "lime",
  "linen",
  "magenta",
  "maroon",
  "moccasin",
  "navy",
  "olive",
  "orange",
  "orchid",
  "peru",
  "pink",
  "plum",
  "purple",
  "red",
  "salmon",
  "sienna",
  "silver",
  "snow",
  "tan",
  "teal",
  "thistle",
  "tomato",
  "turquoise",
  "violet",
  "wheat",
  "white",
  "yellow"
];

const first_char_uppercase = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

const option = {
  one: word => `${word} Photos`,
  two: word => `Photos of ${word}`
};

export const query_phrase = (query, including_message, lowercase) => {
  const lowercase_query = query.toLowerCase();
  const uppercase_query = first_char_uppercase(query);
  const plural_query = pluralize(query);

  let chosen_option = "";

  if (including_message) {
    chosen_option = `Photos Including "${uppercase_query}"`;
  } else {
    if (colors.includes(lowercase_query)) {
      if (lowercase) chosen_option = option.one(query);
      else {
        const uppercased_query = first_char_uppercase(query);
        chosen_option = option.one(uppercased_query);
      }
    } else {
      if (lowercase) chosen_option = option.two(plural_query);
      else {
        const uppercased_query = first_char_uppercase(plural_query);
        chosen_option = option.two(uppercased_query);
      }
    }
  }

  if (lowercase) return chosen_option.toLowerCase();
  else return chosen_option;
};

/*
these methods are used to show queries differently, for example:

query: mountains
Photos of Mountains
1.8k+ free photos including "mountains"

query: red
Red Photos
0.4k+ free photos including "red"
*/