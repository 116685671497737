import React from "react";
import history from "./history";

const Sidebar = (props) => (
  <aside className={`sidebar${props.sidebar_class}`}>
    {props.logged_in ? (
      <>
        <h2>You</h2>

        <button
          className="no-button"
          onClick={() => history.push("/user/library")}
        >
          Library
        </button>

        <button className="no-button" onClick={props.logout}>
          Logout
        </button>
      </>
    ) : (
      <>
        <h2>You</h2>

        <button
          className="no-button get-started"
          onClick={props.toggle_login_modal}
        >
          Log in or register
        </button>
      </>
    )}

    <h2>Pixel Mob</h2>

    <button className="no-button" onClick={() => history.push("/")}>
      Home
    </button>

    <a href="https://pixelmob.co/contact">Get in touch</a>
    <a href="https://pixelmob.co/privacy">Privacy Policy</a>
    <a href="https://pixelmob.co/terms">Terms of Service</a>
  </aside>
);

export default Sidebar;
