import React from "react";

class NeedsEmail extends React.Component {
  state = {
    email: "",
    email_valid: false
  };

  /*
  needsEmail
  if the sign in with email method (for some reason) doesn't have the email, we'll verify it
  this can happen if the user opens the link sent to your email in a different browser or device
  */

  emailChange = event => {
    const email = event.currentTarget.value;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.setState({
      email,
      email_valid: emailRegex.test(email)
    });
  };

  formHandler = event => {
    event.preventDefault();
    this.props.finishLogin(event.currentTarget.email.value);
  };

  render() {
    return (
      <div className="modal-wrapper">
        <form className="register methods" onSubmit={this.formHandler}>
          <p>Please confirm your email address</p>

          <input
            required
            type="email"
            name="email"
            placeholder="Email address"
            value={this.state.email}
            onChange={this.emailChange}
            className={this.state.email_valid ? "good" : "bad"}
          />
          
          <button type="submit" className="email">
            Confirm
          </button>
        </form>
      </div>
    );
  }
}

export default NeedsEmail;
