export const image = path => `${window.location.origin}/images/${path}`;

export const format_large_number = number =>
  Math.abs(number) > 99999
    ? Math.sign(number) * (Math.abs(number) / 100000).toFixed(1) + "m"
    : Math.abs(number) > 999
    ? Math.sign(number) * (Math.abs(number) / 10000).toFixed(1) + "k"
    : Math.sign(number) * Math.abs(number);

/*
format_large_number
this is used to show search result count in a nicer manner
1300000 = 1.3m
*/

export const get_provider = url => {
  let hostname;

  if (url.indexOf("//") > -1) hostname = url.split("/")[2];
  else hostname = url.split("/")[0];

  hostname = hostname.split(":")[0];
  hostname = hostname.split("?")[0];

  return hostname.replace("www.", "");
};

/*
get_provider
get the photo provider based off the image URL
*/

export const db_email = {
  to: email => email.replace(/\./g, ","),
  from: email => email.replace(/,/g, ".")
};

/*
db_email
firebase DB requires keys to not includes periods among other things so these are replaced with commas
hello@pixelmob.co -> hello@pixelmob,co
*/

export const db_path = {
  to: path => path.replace(/\./g, "_period_").replace(/\//g, "_slash_"),
  from: path => path.replace(/_period_/g, ".").replace(/_slash_/g, "/")
};

/*
db_path
these are used for the same reasons as db_email, no slashes are allowed either
*/

export const keys = object => Object.keys(object);