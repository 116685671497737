import React from "react";
import { format_large_number } from "../components/helpers";
import history from "../components/history";
import { query_phrase } from "../helpers/colors";
import IStockButton from "./iStockButton";

class Subheader extends React.Component {
  state = {
    synonyms: [],
    for_word: "",
    disabled: false,
  };

  componentWillReceiveProps(props) {
    if (props.query !== this.state.for_word) {
      this.setState({ synonyms: [], for_word: props.query });

      if (this.state.disabled) return false;

      fetch(`/synonyms?query=${props.query}`)
        .then((response) => response.json())
        .then((synonyms) => this.setState({ synonyms }))
        .catch(this.setState({ disabled: true }));
    }
  }

  render() {
    const { query, results } = this.props;
    const synonyms = this.state.synonyms;
    const count =
      results >= 50
        ? format_large_number(
            results < 500 ? parseInt(`${results}00`) : results
          )
        : results;

    return (
      <>
        <div className="subheader-new">
          <div className="subheader__item">
            <h2>{query_phrase(query)}</h2>
            <small>
              {count}+ free {query_phrase(query, true, true)}
            </small>

            <div className="buttons">
              <IStockButton query={query} />
            </div>
          </div>

          {synonyms ? (
            <div className="subheader__item synonyms">
              {synonyms.map((synonym) => (
                <button
                  onClick={() => history.push(`/search/${synonym}`)}
                  className="no-button synonym"
                  key={synonym}
                >
                  {synonym}
                </button>
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default Subheader;
