import React from "react";
import base from "../components/base";

import ReactGA from "./track";
import firebase from "firebase/app";
import { db_email, db_path, keys } from "../components/helpers";

class Modal extends React.Component {
  state = {
    sponsored_photo: null,
    user_lists: {},
    user: null,
  };

  /*
  Modal
  this is an image modal, or 'popup'
  */

  toggleSave = (list, photo) => {
    const user_lists = this.state.user_lists;
    const distinct_url = db_path.to(photo.photo.url);
    let length = keys(user_lists[list]).length;

    // new lists are saved as false
    if (!user_lists[list]) user_lists[list] = {};

    if (user_lists[list][distinct_url]) {
      user_lists[list][distinct_url] = null;
      length--;
    } else {
      user_lists[list][distinct_url] = photo;
      length++;
    }

    // if list is empty, save it as false so it doesn't delete
    if (length === 0) user_lists[list] = false;

    this.setState({
      user_lists,
    });
  };

  renderListSelector = () => {
    const lists = this.state.user_lists;
    if (!lists) return null;

    const listsWhereHearted = this.findListsWherePhotoIsHearted();

    return keys(lists).map((listKey) => (
      <button
        key={listKey}
        className={
          listsWhereHearted.includes(listKey) ? "saved-in" : "not-saved-in"
        }
        onClick={() => this.toggleSave(listKey, this.props.meta.photo)}
      >
        {listKey}
      </button>
    ));
  };

  findListsWherePhotoIsHearted = () => {
    const user_lists = this.state.user_lists;
    if (this.props.meta.photo) {
      let matches = [];

      const photo = this.props.meta.photo;
      const distinct_url = photo.photo.url;
      keys(user_lists).map((listKey) => {
        return keys(user_lists[listKey]).map((photoKey) => {
          if (db_path.from(photoKey) === distinct_url)
            return matches.push(listKey);
          else return false;
        });
      });

      return matches;
    } else return;
  };

  /*
  findListsWherePhotoIsHearted
  this is to show, in the modal, whether a photo has been added to a certain list
  */

  prep_lists = (user_email) => {
    base.syncState(`user_lists/${db_email.to(user_email)}`, {
      context: this,
      state: "user_lists",
      defaultValue: { Favorites: false },
      then() {
        this.setState({
          active_list_key: "Favorites",
          user: user_email,
        });
      },
    });
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) this.prep_lists(user.email);
    });
  }

  render() {
    if (!this.props.meta) return null;

    const { photo, photographer } = this.props.meta.photo;
    const provider = this.props.meta.provider;
    const sponsored_photos = this.props.sponsored_photos;

    return (
      <div className="overlay">
        <span className="action close" onClick={this.props.openPhoto} />
        <div className="overlay__sidebar">
          <div className="photo-title">
            <span className="sidebar__title">Title</span>

            <span className="sidebar__detail">{photo.description}</span>
          </div>

          <div className="sidebar__author">
            <span className="sidebar__title">Taken by</span>
            <a
              href={photographer.link}
              target="_blank"
              rel="noopener nofollow noreferrer"
              onClick={() =>
                ReactGA.event({
                  category: "Photo",
                  action: "Open photographer link",
                })
              }
            >
              <span className="sidebar__detail underline">
                {photographer.name}
              </span>
            </a>
          </div>

          <div className="sidebar__provider">
            <span className="sidebar__title">Download via</span>
            <a
              href={photo.url}
              target="_blank"
              rel="noopener nofollow noreferrer"
              onClick={() =>
                ReactGA.event({
                  category: "Photo",
                  action: "Download photo",
                  label: provider,
                })
              }
            >
              <span className="sidebar__detail underline" id="_provider">
                {provider.includes("istock") ? "istock.com" : provider}
              </span>
            </a>
          </div>

          <div>
            {this.state.user ? (
              <>
                <span className="sidebar__title">Save this photo</span>

                <span className="sidebar__detail">
                  {this.renderListSelector()}
                </span>
              </>
            ) : (
              <span className="sidebar__title">Login to save this photo</span>
            )}
          </div>

          <div
            className={`sidebar__disclaimer sponsored-photo-container ${
              sponsored_photos ? "sponsored-photo--loaded" : ""
            }`}
          >
            {sponsored_photos && !provider.includes("istock") ? (
              <div className="sponsored-photo-link">
                <span className="sidebar__title sponsored-photo-link">
                  Premium related photos
                </span>
                <div className="sponsored-photos">
                  {sponsored_photos.map((sponsored_photo) => (
                    <a
                      className="sponsored-photo"
                      href={sponsored_photo.photo.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={sponsored_photo.photo.source.medium}
                        alt={`${sponsored_photo.photographer.name}${
                          sponsored_photo.photo.description
                            ? ` - ${sponsored_photo.photo.description}`
                            : ""
                        }`}
                      />
                    </a>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="overlay__image">
          <div className="overlay__image--placeholder">
            <svg
              className="circle"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={10} cy={10} r={5} fill="#C4C4C4" />
            </svg>
            <svg
              className="circle"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={10} cy={10} r={5} fill="#C4C4C4" />
            </svg>
            <svg
              className="circle"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={10} cy={10} r={5} fill="#C4C4C4" />
            </svg>
          </div>
          <img
            src={photo.source.original}
            alt={`${photographer.name}${
              photo.description ? ` - ${photo.description}` : ""
            }`}
          />
        </div>
      </div>
    );
  }
}

export default Modal;
