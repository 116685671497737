import React, { PureComponent } from "react";
import { get_provider } from "./helpers";
import config from "../config";

class Image extends PureComponent {
  /*
  Image
  this class is an item in the grid gallery
  the data in one should never update because they're static so we use PureComponent
  */

  render() {
    const { photo, photographer } = this.props.photo;
    const provider = get_provider(photo.url);
    const is_premium = provider.includes("istock");
    const open_in_new_tab =
      is_premium && config["Open premium photos in a new tab"];

    const renderInnerElements = () => (
      <>
        <img
          alt={`${photographer.name}${
            photo.description ? ` - ${photo.description}` : ""
          }`}
          className="item__image lazyload"
          data-src={photo.source.medium}
          onClick={
            open_in_new_tab
              ? null
              : () => this.props.openPhoto(this.props.photo, provider)
          }
        />
        <div className="item__overlay">
          {open_in_new_tab ? (
            <>
              <span className="action download" />
              <div className="item__meta">
                <span className="meta author">{photographer.name}</span>
              </div>
            </>
          ) : (
            <>
              <a
                className="action download"
                href={photo.url}
                target="_blank"
                rel="noopener noreferrer nofollow"
              />
              <div className="item__meta">
                <a
                  className="meta author"
                  href={photographer.link}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {photographer.name}
                </a>
              </div>
            </>
          )}
        </div>
      </>
    );

    return open_in_new_tab ? (
      <a
        className={`item${is_premium ? " premium" : ""}${
          this.props.long && !is_premium ? " long" : ""
        }`}
        href={photo.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {renderInnerElements()}
      </a>
    ) : (
      <div
        className={`item${is_premium ? " premium" : ""}${
          this.props.long && !is_premium ? " long" : ""
        }`}
      >
        {renderInnerElements()}
      </div>
    );
  }
}

export default Image;
