import React from "react";
import firebase from "firebase/app";
import { Router, Route, Switch } from "react-router-dom";
import history from "./../components/history";
import Header from "./../components/Header";
import ls from "local-storage";

import Login from "./Login";
import Search from "./Search";
import Landing from "./Landing";
import NotFound from "./NotFound";
import Library from "./Library";

import ReactGA from "../components/track";

import "lazysizes";

class App extends React.Component {
  state = {
    needs_email: false
  };

  /*
  'needs_email' is a variable that is set below
  it is there to sign on with email, explained more in other functions
  */

  track = location => ReactGA.pageview(location.pathname);

  /*
    track is for google analytics
  */

  componentDidMount() {
    if (history.location.pathname === "/auth") return false;
    if (/\?query=/.test(history.location.search)) {
      const query = window.location.search.replace(/\?query=/, "");
      history.push(`/search/${query}`);
    }

    /*
     above is for processing old links on PM
     previously, instead of /search/mountains it would be /search?query=mountains
     this prevents dead links on google
     */

    this.track(window.location);
    history.listen(this.track);

    /*
    history.listen is saying every time our url changes, track it so GA knows where we are
    */
  }

  finishLogin = email => {
    /*
    finishLogin is called when signing in with email
    it verifies that we are actually signing in with email, and then signs in
    it uses local-storage to verify this along with the URL
    */

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const stored_email = ls.get("email-login");
      if (stored_email) email = stored_email;
      else {
        this.setState({
          needs_email: true
        });
      }

      if (email) {
        const needs_email = this.state.needs_email;
        if (needs_email) {
          this.setState({
            needs_email: false
          });
        }

        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(() => {
            ls.remove("email-login");
            history.push("/");
          });
      }
    }
  };

  searchHandler = term => history.push(`/search/${term}`);

  render() {
    /*
    this is our 'navigator'
    unsure how familiar you are with react so will explain anyway
    a 'route', instead of having an html file for each page, it dynamically creates the routes and switches up the JS files depending on the URL
    we use 'react-router-dom' package for this
    */
    return (
      <Header
        user={this.state.user}
        authenticate={this.authenticate}
        login_ready={this.state.login_ready}
        logout={this.logout}
      >
        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/auth"
              render={props => (
                <Landing
                  {...props}
                  searchHandler={this.searchHandler}
                  email={true}
                  finishLogin={this.finishLogin}
                  needs_email={this.state.needs_email}
                />
              )}
            />

            <Route
              exact
              path="/"
              render={props => (
                <Landing {...props} searchHandler={this.searchHandler} />
              )}
            />

            <Route
              exact
              path="/login"
              render={props => (
                <Login {...props} />
              )}
            />

            <Route
              exact
              path="/search"
              render={props => (
                <Landing {...props} searchHandler={this.searchHandler} />
              )}
            />

            <Route
              exact
              path="/search/:query"
              render={props => <Search {...props} />}
            />

            <Route
              exact
              path="/user/library"
              render={props => <Library {...props} />}
            />

            <Route component={NotFound} />
          </Switch>
        </Router>
      </Header>
    );
  }
}

export default App;
