import Rebase from "re-base";
import firebase from "firebase/app";
import "firebase/database";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBMlbP05jNqkWyrfaNWzzCOWjOAacuC8Vs",
  authDomain: "pixel-mob-1547827448510.firebaseapp.com",
  databaseURL: "https://pixel-mob-1547827448510.firebaseio.com"
});

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp };

export default base;


/*
firebase config
*/