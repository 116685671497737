import React from "react";

class ListBar extends React.Component {
  state = {
    is_editing: false,
    list_name: ""
  };

  /*
  ListBar
  this is for the 'list bar' at the top of the user library page
  */

  nameChangeHandler = event => {
    this.setState({
      list_name: event.currentTarget.value
    });
  };

  submitNameChange = event => {
    if (event.key === "Enter") {
      this.props.newList(this.state.list_name);

      this.setState({
        list_name: "",
        is_editing: false
      });
    }
  };

  toggleEditing = () => {
    this.setState(
      {
        is_editing: true
      },
      () => document.getElementById("editing-area").focus()
    );
  };

  deleteList = () => {
    if (this.props.active_list === "Favorites") {
      alert("You can't delete your favorites.");
    } else {
      if (
        window.confirm(
          `Are you sure you want to delete the list "${
            this.props.active_list
          }"?`
        )
      ) {
        this.props.deleteList(this.props.active_list);
      }
    }
  };

  render() {
    return (
      <div className="subheader notification">
        <div className="subheader__item">
          {this.state.is_editing ? (
            <input
              type="text"
              value={this.state.list_name}
              onChange={this.nameChangeHandler}
              onKeyDown={this.submitNameChange}
              maxLength={10}
              className="editing-area"
              id="editing-area"
              placeholder="List name"
            />
          ) : (
            <React.Fragment>
              <button className="no-button styled" onClick={() => this.toggleEditing()}>New list</button>
              <button className="no-button styled" onClick={() => this.deleteList()}>Delete list</button>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default ListBar;
