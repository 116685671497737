import React from "react";
import SearchBar from "../components/SearchBar";
import history from "../components/history";
import NeedsEmail from "../components/NeedsEmail";
import Meta from "../components/meta";

class Landing extends React.Component {
  suggestions = ["mountains", "makeup", "technology", "landscape"];

  /*
  suggestions are shown on the homepage, can be altered at any time
  these are clicked frequently as per GA, so they could be used to harness high click-thru searches to iStock
  */

  render() {
    if (history.location.pathname === "/auth" && !this.props.needs_email)
      this.props.finishLogin();

    return (
      <>
        <Meta />

        <div className="landing hero">
          {this.props.needs_email ? (
            <NeedsEmail finishLogin={this.props.finishLogin} />
          ) : null}

          <img
            alt="Aerial by Splitshire"
            className="hero__image"
            src="/images/splash.webp"
          />
          <div className="hero__text">
            <h2>
              Search <span className="underline">millions</span> of royalty-free
              photos from around the web
            </h2>
            <SearchBar searchHandler={this.props.searchHandler} focus={true} />

            <div className="suggestions">
              Trending:{" "}
              {this.suggestions.map((suggestion, index) => (
                <button
                  onClick={() => history.push(`/search/${suggestion}`)}
                  className="no-button"
                  key={suggestion}
                  dangerouslySetInnerHTML={{
                    __html: `<span>${suggestion}</span>${
                      index < this.suggestions.length - 1 ? ", " : ""
                    }`
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Landing;
