import React, { useState, useEffect } from "react";
import "./style.scss";
import base from "../base";

const Button = ({ query, text, background_color, border_color, link }) => {
  link = (link || "").replace("{query}", query);

  return (
    <a
      href={link}
      className={`subheader__button istock-button ${text ? "visible" : ""}`}
      target="_blank"
      rel="noreferrer nofollow noopener"
      style={{
        borderColor: border_color,
        backgroundColor: background_color,
      }}
    >
      {text}
    </a>
  );
};

const IStockButton = ({ query }) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    base.fetch("/config/istock_button", { context: this }).then(setConfig);
  }, []);

  if (!config) return <Button query={query} />;

  const { text, background_color, border_color, link } = config;

  return (
    <Button
      query={query}
      text={text}
      background_color={background_color}
      border_color={border_color}
      link={link}
    />
  );
};

export default IStockButton;
