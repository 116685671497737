import React from "react";
import base from "../components/base";
import ls from "local-storage";

class AnnouncementBar extends React.Component {
  state = { announcement: null };

  /*
  AnnouncementBar
  this class is used to show announcement bars
  the text can be changed in the firebase DB
  */

  componentDidMount = () => {
    base.fetch("config/announcement", {
      then: announcement => {
        const seen = ls.get("announcement-seen");
        if (seen === announcement) return false;
        this.setState({ announcement });
      }
    });
  };

  seen_it = () => {
    ls.set("announcement-seen", this.state.announcement);
    this.setState({ announcement: null });
  };

  /*
  seen_it
  you can 'x' our an announcement bar, when a message is changed it is reset and stored in local-storage so the user won't see it again
  */

  render = () =>
    this.state.announcement ? (
      <div className="announcement-bar">
        <span dangerouslySetInnerHTML={{ __html: this.state.announcement }} />
        <button className="no-button seen" onClick={this.seen_it}>
          Close
        </button>
      </div>
    ) : null;
}

export default AnnouncementBar;
