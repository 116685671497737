import React from "react";
import Gravatar from "react-gravatar";
import history from "./history";

import SearchBar from "./SearchBar";

import { image } from "./helpers";
import Sidebar from "./Sidebar";
import LoginModal from "./LoginModal";

import firebase from "firebase/app";

class Header extends React.Component {
  state = {
    sidebar_open: false,
    login_modal_open: false,
    on_landing: true,
    user: null,
  };

  authenticate = async (action) => {
    const authProvider = new firebase.auth[`${action}AuthProvider`]();

    firebase.auth().signInWithRedirect(authProvider);
  };

  logout = async () => {
    await firebase.auth().signOut();
    this.setState({ user: false });

    if (window.location.pathname.includes("/user/")) history.push("/");
  };

  toggle_sidebar = () => {
    this.setState({
      sidebar_open: !this.state.sidebar_open,
      login_modal_open: false,
    });
  };

  toggle_login_modal = () => {
    this.setState({
      login_modal_open: !this.state.login_modal_open,
      sidebar_open: false,
    });
  };

  listSelectHandler = (event) => {
    this.props.changeActiveList(event.currentTarget.value);
  };

  componentDidMount() {
    const check_history = (pathname) => {
      if (["/", "/search", "/search/"].includes(pathname))
        this.setState({ on_landing: true });
      else this.setState({ on_landing: false });
    };

    check_history(history.location.pathname);

    history.listen((location) => {
      check_history(location.pathname);
      this.setState({
        sidebar_open: false,
        login_modal_open: false,
      });
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) this.setState({ user: user.email });
      else this.setState({ user: false });
    });
  }

  render() {
    const user = this.state.user;
    const logged_in = !!user;
    const sidebar_class = this.state.sidebar_open ? " open" : "";
    const on_landing = this.state.on_landing;
    const header_class =
      on_landing && !this.state.login_modal_open ? " on-landing" : "";

    return (
      <>
        <header className={`${header_class}${user !== null ? " ready" : ""}`}>
          <a href="https://pixelmob.co" className="no-button logo">
            <h1>
              <img alt="Pixelmob" src={image("logo.svg")} />
            </h1>
          </a>

          {!on_landing ? (
            <SearchBar
              searchHandler={this.props.searchHandler}
              query={this.props.query}
            />
          ) : null}

          {this.state.login_modal_open ? (
            <LoginModal authenticate={this.authenticate} />
          ) : null}

          <nav>
            <button className="no-button menu" onClick={this.toggle_sidebar}>
              Menu
            </button>

            {logged_in ? (
              <Gravatar email={user} />
            ) : !on_landing ? (
              <button
                className="no-button get-started"
                onClick={this.toggle_login_modal}
              >
                Get started
              </button>
            ) : null}
          </nav>
        </header>

        <Sidebar
          logged_in={logged_in}
          sidebar_class={sidebar_class}
          logout={this.logout}
          toggle_login_modal={this.toggle_login_modal}
        />

        {this.props.children}
      </>
    );
  }
}

export default Header;
