import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import ls from "local-storage";

class LoginModal extends React.Component {
  state = {
    status: false,
    email: "",
    email_valid: false
  };

  emailChange = event => {
    const email = event.currentTarget.value;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    this.setState({
      email,
      email_valid: emailRegex.test(email)
    });
  };

  formHandler = event => {
    event.preventDefault();

    const email = this.state.email;

    const actionCodeSettings = {
      url: window.location.origin + "/auth",
      handleCodeInApp: true
    };

    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        ls.set("email-login", email);

        this.setState({
          status: "Success! Check your email to login."
        });
      })
      .catch(error => {
        this.setState({
          status: "Woops! Something went wrong."
        });
        console.log(error);
      });
  };

  render() {
    return (
      <div className="login-modal">
        <h2>Join the Pixel Mob.</h2>
        <p>Where millions of royalty-free photos are at your fingertips.</p>

        <div className="methods">
          <button
            className="google"
            onClick={() => this.props.authenticate("Google")}
          >
            Login with Google
          </button>

          <button
            className="facebook"
            onClick={() => this.props.authenticate("Facebook")}
          >
            Login with Facebook
          </button>

          <form
            className="register"
            onSubmit={event => this.formHandler(event, this.props.action)}
          >
            <input
              required
              type="email"
              name="email"
              placeholder="Email address"
              value={this.state.email}
              onChange={this.emailChange}
              className={this.state.email_valid ? "good" : "bad"}
            />
            <button type="submit" className="email">
              Login / Register
            </button>
          </form>

          {this.state.status ? (
            <span className="status">{this.state.status}</span>
          ) : null}
        </div>
      </div>
    );
  }
}

export default LoginModal;
